* {
  margin: 0;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  --light-gray: rgb(248, 248, 250);
  --light-teal: #9dcbcf;
  --teal: #308b94;
  --dark-teal: #1f5d63;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  text-transform: uppercase;
  margin-bottom: 20px;
}

p, li, input.form-control, textarea.form-control {
  font-size: 17px;
  color: var(--dark-teal);
}

h2, h3, h4, a, i {
  color: var(--teal);
}

h2 {
  margin-bottom: 15px;
}

h4 {
  margin-bottom: 15px;
}

a:hover {
  color: var(--dark-teal);
  text-decoration: none;
}

input.form-control, textarea.form-control {
  border-radius: 0;
  border-color: var(--teal);
}

input.form-control:focus,
textarea.form-control:focus {
  border-color: var(--teal);
  box-shadow: 0 0 0 0.2rem var(--light-teal);
}

hr {
  height: 1px;
  background-color: var(--light-gray);
  margin-top: 30px;
  margin-bottom: 30px;
}

@media all and (min-width: 800px) {
  hr {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.navbar {
  background-color: var(--teal);
}

.navbar-brand {
  padding: 0;
}

.navbar-brand img {
  height: 60px;
}

.navbar-toggler {
  border: none;
  padding: 0;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("hamburger.svg");
}

.nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

.nav-link h4 {
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  padding-bottom: 2px;
  display: inline-block;
  border-bottom: solid 2px transparent;
}

.nav-link.active h4 {
  border-bottom: solid 2px white;
}

@media all and (min-width: 800px) {
  .nav-link h4 {
    margin-top: 8px;
    padding-top: 8px;
  }
}

button.btn.btn-primary.btn-lg {
  border-radius: 0;
  background-color: var(--teal);
  border: none;
  padding: 20px 25px;
  text-transform: uppercase;
}

button.btn.btn-primary.btn-lg:hover,
button.btn.btn-primary.btn-lg:active {
  background-color: var(--dark-teal);
}

button.btn.btn-primary.btn-lg {
  box-shadow: none;
}

.page {
  position: absolute;
  left: 0;
  right: 0;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.content-container {
  max-width: min(1000px, calc(100vw - 14%));
  min-height: calc(100vh - 76px);
  text-align: left;
  padding-top: 7%;
  padding-bottom: calc(7% + 125px);
  margin-left: auto;
  margin-right: auto;
}

@media all and (min-width: 995px) {
  .content-container {
    padding-bottom: calc(7% + 80px);
  }
}

.home {
  max-width: calc(100vw - 10%);
  text-align: center;
}

.home-content {
  display: flex;
  justify-content: flex-start;
  max-width: 530px;
}

.home img {
  object-position: 25% 18%;
}

@media all and (min-width: 620px) {
  .home {
    text-align: left;
  }

  .home h1 {
    max-width: 350px;
  }
}

.home * {
  color: white;
}

.home p {
  margin-bottom: 20px;
}

@media all and (min-width: 600px) {
  .home p {
    margin-bottom: 30px;
  }
}

.home-page-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(40%);
}

.about-img-container {
  width: 100%;
  min-height: 300px;
  float: left;
  margin-bottom: 25px;
}

.about-img-container img {
  width: 100%;
}

@media all and (min-width: 600px) {
  .about-img-container {
    width: calc(50% - 15px);
    margin-right: 50px;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 8px;
  padding-right: 8px;
  height: 125px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--teal);
}

@media all and (min-width: 620px) {
  .footer {
    height: 80px;
  }
}

.footer p, .footer a {
  font-size: 16px;
  margin-bottom: 0px;
  color: white;
}

.footer a {
  border-bottom: solid 1px white;
}

.footer a:hover {
  color: white;
}

.desktop {
  display: none;
}

@media all and (min-width: 600px) {
  .desktop {
    display: unset;
  }

  .mobile {
    display: none;
  }
}

.who-we-serve {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}

.who-we-serve img {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

ul {
  padding-left: 20px;
}

@media all and (min-width: 800px) {
  .service-description {
    width: 50%;
  }

  .who-we-serve img {
    width: 50%;
    object-fit: contain;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .service .service-description {
    padding-right: 30px;
  }

  .service img {
    padding-left: 30px;
  }
}

@media all and (min-width: 600px) {
  .who-we-serve {
    margin-top: 15px;
  }
}

.services-provided {
  display: flex;
  flex-wrap: wrap;
}

.service {
  border: solid 1px var(--teal);
  background-color: var(--light-gray);
  padding: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media all and (min-width: 800px) {
  .service {
    max-width: calc(50% - 20px);
    margin: 20px;
  }

  .service:first-of-type {
    margin-left: 0;
  }
  
  .service:last-of-type {
    margin-right: 0;
  }
}

.faq {
  margin-top: 30px;
}

.testimonial {
  padding: 20px;
  margin-top: 30px;
  border: solid 1px var(--dark-teal);
  background-color: var(--light-gray);
}

.quote {
  margin-bottom: 0;
}

.source {
  text-align: right;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 600;
}

.resources {
  margin-bottom: 30px;
}

@media all and (min-width: 600px) {
  .resources {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.resources-title {
  margin-bottom: 60px;
}

@media all and (min-width: 600px) {
  .resources-title {
    text-align: center;
  }
}

.resource {
  margin-bottom: 30px;
  display: flex;
}

@media all and (min-width: 600px) {
  .resource {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.resource:last-of-type {
  margin-bottom: 0;
}

.resource:hover * {
  color: var(--dark-teal);
}

.resource i {
  margin-right: 5px;
  font-size: 24px;
}

.resource h4 {
  margin-left: 5px;
}

@media all and (min-width: 600px) {
  .patient-portal {
    text-align: center;
  }
}

.contact {
  max-width: min(600px, calc(100vw - 14%));
}
